import {
  QuoteFormCalculate,
  quoteFormCalculateDefaultValue,
} from "./QuoteFormCalculate";
import { FINANCE_TYPE } from "../constants/quote";

export interface QuoteFormSave extends QuoteFormCalculate {
  financeType?: FINANCE_TYPE;
  assetType?: string;
  hasStructuredPayment?: boolean;
  structuredPaymentNthPaymentAmount?: string;
  structuredPaymentAmount?: string;
}

export const quoteFormSaveDefaultValue: QuoteFormSave = {
  ...quoteFormCalculateDefaultValue,
  financeType: FINANCE_TYPE.CHATTEL_MORTGAGE,
  assetType: "",
  hasStructuredPayment: false,
  structuredPaymentNthPaymentAmount: "",
  structuredPaymentAmount: "",
};
