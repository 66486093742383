import { Dictionary } from "../../common/types/Dictionary";

export const QUOTE_MIN_PURCHASE_AMOUNT = 5000;
export const QUOTE_MIN_ASSET_MODEL_YEAR = 1900;
export const QUOTE_NOMINAL_MIN_AMOUNT = 0;
export const QUOTE_NOMINAL_MAX_AMOUNT = 10000000;
export const QUOTE_PERCENTAGE_MIN_AMOUNT = 0;
export const QUOTE_PERCENTAGE_MAX_AMOUNT = 99;
export const QUOTE_MIN_BROKERAGE_ORIGINATION_FEE_AMOUNT = 0;
export const QUOTE_MAX_BROKERAGE_ORIGINATION_FEE_AMOUNT = 10000;
export const QUOTE_MIN_APPLICATION_FEE_AMOUNT = 0;
export const QUOTE_MAX_APPLICATION_FEE_AMOUNT = 10000;
export enum QUOTE_ADVANCE_OR_ARREARS {
  ADVANCE = "ADVANCE",
  ARREARS = "ARREARS",
}

export const REPAYMENT_TYPE_OPTION_LABELS: Dictionary = {
  [QUOTE_ADVANCE_OR_ARREARS.ADVANCE]: "Advance",
  [QUOTE_ADVANCE_OR_ARREARS.ARREARS]: "Arrears",
};

export enum FINANCE_TYPE {
  CHATTEL_MORTGAGE = "CHATTEL_MORTGAGE",
  BUSINESS_LOAN = "BUSINESS_LOAN",
}

export const FINANCE_TYPE_LABELS: Dictionary = {
  [FINANCE_TYPE.CHATTEL_MORTGAGE]: "Chattel Mortgage",
  [FINANCE_TYPE.BUSINESS_LOAN]: "Business Loan",
};
